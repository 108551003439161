import { DateTime } from "luxon";

// edgeType: 'start' or 'end'
export const makeDateTime = (edgeType='start',dt) => {
    if (dt == null) dt = DateTime.now();
    return dt[`${edgeType}Of`]('day');
};
export const makeEndOfToday = (suppressSeconds = true) => {
    const dt = makeDateTime('end');
    if (!suppressSeconds) return dt;
    return dt.set({ second: 0, millisecond: 0 });
};
export const makeStartOfToday     = () => makeDateTime();
export const makeStartOfYesterday = () => makeStartOfToday().minus({days: 1});
export const makeEndOfYesterday   = () => makeEndOfToday().minus({days: 1});

export const minEdgeOfDate = () => makeStartOfToday().minus({years: 3});
export const maxEdgeOfDate = () => makeEndOfToday();
export const minEdgeOfDateString = () => minEdgeOfDate().toISODate();
export const maxEdgeOfDateString = () => maxEdgeOfDate().toISODate();
export const makeMinEdgeOfSelectedDate = (dt) => {
    const target = dt.minus({years: 1}).startOf('day');
    const min = minEdgeOfDate();
    return min > target ? min : target;
};
export const makeMaxEdgeOfSelectedDate = (dt) => {
    const target = dt.plus({years: 1}).endOf('day');
    const max = maxEdgeOfDate();
    return max < target ? max : target;
};
