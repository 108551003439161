<template>
    <div>
        <div class="row label-color mb-3">
            <div v-show="hasCompanySelectAuth(roleId)" class="col-sm-6 col-md-4 col-lg-3">
                <label>会社名</label>
                <CompanyList
                    ref="companyList"
                    elm-id="select-company"
                    :store-id="storeId"
                    :include-all="true"
                    :show-with-code="hasCompanySelectAuth(roleId)"
                    @data-loaded="handleDataLoaded"
                    label-all="全て"
                ></CompanyList>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-location">拠点名</label>
                <LocationList
                    ref="locationList"
                    elm-id="select-location"
                    :store-id="storeId"
                    :include-all="true"
                    @data-loaded="handleDataLoaded"
                    label-all="全て"
                ></LocationList>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-id">デバイスID</label>
                <div class="col-md-12">
                    <input
                        v-model="deviceId"
                        @keypress.enter.prevent
                        id="select-id"
                        autocomplete="false"
                        type="text"
                        class="form-control mdb-autocomplete"
                        name="select-name"
                        placeholder=""
                    >
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-name">デバイス名</label>
                <div class="col-md-12">
                    <input
                        v-model="deviceName"
                        @keypress.enter.prevent
                        id="select-name"
                        autocomplete="false"
                        type="text"
                        class="form-control mdb-autocomplete"
                        name="select-name"
                        placeholder=""
                    >
                </div>
            </div>
        </div>

        <div class="row btn-spacer"></div>

        <div class="row">
            <div class="col"></div>
            <div class="col-12 col-md-6 d-flex justify-content-center">
	        <button
                    class="btn mx-1 btn-reset flex-fill"
                    @click="onClickClearSearchParams"
                >
		    検索条件をリセット
	        </button>
	        <button
                    :disabled="isLoading"
                    class="btn mx-1 btn-search flex-fill"
                    @click="onClickSearch"
                >
                    <img src="/images/search.svg" width="20px" />
                    この条件で検索
	        </button>
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col ms-auto text-end">
                <button
                    @click="onClickDownload"
                    type="button"
                    class="btn btn-light link-style-button"
                    id="download-csv"
                    style="cursor:pointer;"
                >
                    <img src="/images/download.svg"
                         class="calendar_div mx-1" width="20" height="20" />
                    <span class="download_data">ダウンロード</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
 import CompanyList from '@/components/CompanyList.vue';
import LocationList from '@/components/LocationList.vue';
import { hasCompanySelectAuth } from '@/composables/common.js';
import { createCompanyLocationParamState } from '@/stores/companylocation';

 export default {
     setup() {
         return {
             hasCompanySelectAuth,
         };
     },
     components: {
         CompanyList,
         LocationList,
     },
     props: {
         roleId: {type: Number, required: true},
     },
     computed: {
         isLoading() {
             if (this.params.isLoading) return true;
             return false;
         },
     },
     data: function() {
         return {
             storeId: 'search-list',
             params: createCompanyLocationParamState('search-list'),
             deviceId: '',
             deviceName: '',
             initialized: false,
             listComponentStates: {company: true, location: true},
         };
     },
     mounted: async function() {
     },
     methods: {
         onClickSearch(event) {
             event.preventDefault();
             reloadTable();
         },
         onClickDownload(event) {
             event.preventDefault();
             handleClickDownloadXlsx();
         },
         onClickClearSearchParams(event) {
             event.preventDefault();
             this.$refs.companyList.clearSelection();
             this.$refs.locationList.clearSelection();
             this.deviceId = '';
             this.deviceName = '';
         },
         handleDataLoaded(data) {
             for (const [key, value] of Object.entries(data)) {
                 this.listComponentStates[key] = value;
             }
             if (this.initialized) return;
             const allCompleted = Object.values(this.listComponentStates)
                                        .every(value => value === true);
             if (allCompleted) {
                 reloadTable();
                 this.initialized = true;
             }
         },
     },
     watch: {
     },
 }
</script>
