<template>
	<div class='d-block'>
            <!-- <div class="col-12 d-md-block hidden">&nbsp;</div> -->
        <div class='d-md-flex ml-auto ms-auto'>
		<button
                    :disabled="isLoading"
                    class="col-md-6 col-12 btn btn-primary mx-1" @click="onClickReset">
		    検索条件をリセット
		</button>
        <button
                    :disabled="isLoading"
                    class="col-md-6 col-12 btn btn-primary mx-1" @click="onClickSearch">
            この条件で検索
        </button>
	    </div>
	</div>
</template>

<script>
    import axios from '@/composables/axios.js';
    import { createCompanyLocationParamState } from '@/stores/companylocation';
    import { useCommonStore } from '@/stores/common';
    export default {
        components: {
        },
        props: {
            storeId:{type: String, require: true},
        },
        computed: {
            // XXX mapper
            isMaintenanceMode() {
                return this.st.driver.isMaintenanceMode;
            },
            isLoading() {
                if (this.params.isLoading) return true;
                return false;
            },
        },
        data: function() {
            return {
                isDisable: true,
                params: createCompanyLocationParamState(this.storeId),
                st: useCommonStore(),
            };
        },
        mounted: async function() {
        },
        methods: {
            async onClickSearch() {
                license_search();
            },
            async onClickReset() {
                license_reset();
            },
        },
        watch: {
        },
    }
</script>
<style scoped>
    #btn_showdlist,#btn_back {
        background-color: #e6f5ff;
        border-top-color: #e6f5ff;
        border-left-color: #e6f5ff;
        border-right-color: #e6f5ff;
        border-bottom-color: #e6f5ff;
    }
</style>
