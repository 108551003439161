import { defineStore } from 'pinia';

export const useCommonStore = defineStore({
    id: 'commonStore',
    state: () => ({
        driver: {
            isMaintenanceMode: false,
        },
//        count: 0,
    }),
    actions: {
//        increment() {
//            this.count++;
//        },
//        decrement() {
//            this.count--;
//        },
    },
});
