/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/**
 * Additionally, we will set up Vue 3.x for use in the application.
 */

import { createApp } from 'vue';
import { createPinia } from 'pinia';
//import ExampleComponent from './components/ExampleComponent.vue';
import SurveyModal from './components/common/SurveyModal.vue';
import CompanyList from './components/CompanyList.vue';
import LocationList from './components/LocationList.vue';

import DriverPage from './components/pages/Driver.vue';
import UserPage from './components/pages/User.vue';
import DevicePage from './components/pages/Device.vue';
import VehiclePage from './components/pages/Vehicle.vue';
import LocationPage from './components/pages/Location.vue';
import ResultPage from './components/pages/Result.vue';
import VehicleHistoryPage from './components/pages/VehicleHistory.vue';
import LicenseButton from './components/parts/LicenseButton.vue';
import LicenseCsvButton from './components/parts/LicenseCsvButton.vue';
import LicensePage from './components/pages/License.vue';

document.addEventListener('DOMContentLoaded', () => {
    const app = createApp({});
    const pinia = createPinia();

    //app.component('example-component', ExampleComponent);
    app.component('survey-modal', SurveyModal);
    app.component('company-list', CompanyList);
    app.component('location-list', LocationList);

    app.component('driver-page', DriverPage);
    app.component('user-page', UserPage);
    app.component('device-page', DevicePage);
    app.component('vehicle-page', VehiclePage);
    app.component('location-page', LocationPage);
    app.component('result-page', ResultPage);
    app.component('vehicle-history-page', VehicleHistoryPage);
    app.component('license-page', LicensePage);

    if (process.env.NODE_ENV === 'development') {
        app.config.devtools = true;
    }

    app.use(pinia);
    app.mount('#app');

    window.clearModalCompanyList = function(modalId,selectId){
        const $modalRoot = $(`#${modalId}`);
        const $select = $(`select#${selectId}`,$modalRoot);
        $select.empty();
    };
    window.updateModalCompanyList = async function(roleId,modalId,selectId){
        const $modalRoot = $(`#${modalId}`);
        const response = await axios.get('/company/list');
        const companies = response.data;
        const $select = $(`select#${selectId}`,$modalRoot);
        $select.empty();
        if (roleId != 2) {
            const company = companies[0];
            $select.append(makeSelectOption(company.id,company.name));
        } else {
            $select.append(makeSelectOption('',''));
            for (const company of companies) {
                $select.append(makeSelectOption(company.id,company.name));
            }
        }
    };
    window.makeSelectOption = function(value,text) {
        const maxLen = 40;
        const $opt = $('<option />');
        const trimmedText = text.length > maxLen ? text.substring(0, maxLen) + '...' : text;
        $opt.val(value).text(trimmedText);
        return $opt;
    };
});
