<template>
    <select
        class="form-select"
        ref="locationSelect"
        v-model="params.locationSelected"
        :id="elmId"
        :disabled="isSelectDisabled">
        <option v-if="hasBlankItem" value="" selected>{{ labelAll }}</option>
	<option
            v-for="(location,index) in params.locations"
            :key="location.id"
            :value="location.id"
            :selected="index === 0 && !includeAll">
            {{ makeDispLabel(location) }}
        </option>
    </select>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { isStringEmpty } from '@/composables/common.js';
 import { createCompanyLocationParamState } from '@/stores/companylocation';
 export default {
     components: {
     },
     props: {
         elmId: {type: String, require: true},
         storeId: {type: String, require: true},
         includeAll: {type: Boolean, default: true},
         labelAll: {type: String, default: ''},
         showWithCode: {type: Boolean, default: false},
         fixSingleItem: {type: Boolean, default: false},
     },
     computed: {
         isSelectDisabled() {
             if (this.isDisable) return true;
             if (this.fixSingleItem && this.params.locations.length == 1) return true;
             return this.params.locations.length < 1;
         },
         hasBlankItem() {
             if (!this.includeAll) return false;
             if (!this.fixSingleItem && this.params.locations.length == 1) return true;
             return this.params.locations.length >= 2
         },
     },
     data: function() {
         return {
             isDisable: true,
             select: null,
             params: createCompanyLocationParamState(this.storeId),
             selectArgs: {
                 search: true,
                 searchText: '',
                 placeholder: '絞り込み',
                 noMatch: ''
             },
         };
     },
     mounted: async function() {
         this.updateLocationList();
     },
     methods: {
         async updateLocationList() {
             if (isStringEmpty(this.params.companySelected)) return;
             this.$emit('data-loaded',{location: false});
             await this.getLocationList();
             this.isDisable = false;
             this.bindSumoSelect();
             this.$emit('data-loaded',{location: true});
         },
         async getLocationList() {
             try {
                 this.params.isLocationLoading = true;
                 const response = await axios.get('/location/list',{
                     params: {
                         company_id: this.params.companySelected,
                     },
                 });
                 this.params.locations = response.data;
                 if (this.fixSingleItem && this.params.locations.length == 1) {
                     this.params.locationSelected = this.params.locations[0].id;
                 }
             } catch (error) {
                 console.error('Error fetching message:', error);
             } finally {
                 this.params.isLocationLoading = false;
             }
         },
         bindSumoSelect() {
             const $select = $(this.$refs.locationSelect); 
             const args = this.selectArgs;
             this.$nextTick(function () {
                 $(function () {$select.SumoSelect(args);});
             });
         },
         destroySumoSelect() {
             const $select = $(this.$refs.locationSelect); 
             const args = this.selectArgs;
             this.$nextTick(function () {
                 $(function () {
                     if ($select.SumoSelect(args).sumo) {
                         $select.SumoSelect(args).sumo.unload();
                     }
                 });
             });
         },
         disableSelect() {
             this.destroySumoSelect();
             this.isDisable = true;
         },
         makeDispLabel(location) {
             const name = location.name;
             if (!this.showWithCode) return name;
             const code = location.code;
             return `${name}(${code})`;
         },
         clearSelection() {
             this.params.locationSelected = '';
             const $select = $(this.$refs.locationSelect);
             if ($select[0].sumo) {
                 try {
                     $select[0].sumo.selectItem(0);
                 } catch(error) {
                     // カラッポの場合もあるのでエラー無視
                 }
             }
         },
     },
     watch: {
         'params.companySelected': {
             handler(newValue,oldValue) {
                 //console.info(`newValue: ${newValue}, oldValue: ${oldValue}`);
                 this.params.locationSelected = '';
                 this.params.locations = [];
                 this.disableSelect();
                 if (!isStringEmpty(newValue)) {
                     this.updateLocationList();
                 }
             },
             immediate: true,
         },
     },
 }
</script>
